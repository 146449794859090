body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.App {
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.social-container {
  padding: 25px 50px;
}
a.social {
  margin: 1rem 1rem;
  transition: -webkit-transform 250ms;
  transition: transform 250ms;
  transition: transform 250ms, -webkit-transform 250ms;
  display: inline-block;
}
a.social:hover {
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
}
a.linkedin {
  color: #0077b5;
}
a.twitter {
  color: #55acee;
}
a.facebook {
  color: #3b5999;
}
a.instagram {
  color: #e4405f;
}
a.github {
  color: black;
}
